export var initResponsiveNavigation = function () {
  var mainEl = ".navigation-main"
  var mainTargetSelectors = document.querySelectorAll(
    ".js-main-navigation-toggle"
  )
  for (let i = 0; i < mainTargetSelectors.length; i++) {
    mainTargetSelectors[i].addEventListener("click", function (event) {
      event.preventDefault()
      var parentNavTarget = event.target.closest(mainEl)
      if (parentNavTarget.classList.contains("is-opened")) {
        parentNavTarget.classList.remove("is-opened")
      } else {
        parentNavTarget.classList.add("is-opened")
      }
    })
  }
}
