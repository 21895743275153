var $ = require("jquery")

import { initResponsiveNavigation } from "./navigation"
import { initToggleGallery } from "./toggleGallery"
import { initScrollButton } from "./scrollButton"
import { initImageEnlargement } from "./article-image-enlargement"
import { initBlog } from "./blog"

initResponsiveNavigation()
initToggleGallery()
initScrollButton()

import initCookies from "./cookieConsent"

document.addEventListener("DOMContentLoaded", () => {
  initCookies()
  initImageEnlargement()
  initBlog()
})
