const ARTICLE_ENLARGEMENT_TOGGLE_SELECTOR = ".js-article-enlargement"
const ARTICLE_ENLARGED_IMAGE_CLASS = "article__image--enlarged"

function openImage(image, state) {
  state.enlargedImage = image
  return image.classList.add(ARTICLE_ENLARGED_IMAGE_CLASS)
}

function closeCurrentImage(state) {
  state.enlargedImage?.classList.remove(ARTICLE_ENLARGED_IMAGE_CLASS)
  state.enlargedImage = null
  return
}

const getImageEnlargementState = () => {
  const articleWithEnlargement = document.querySelector(
    ARTICLE_ENLARGEMENT_TOGGLE_SELECTOR
  )
  return {
    enlargedImage: null,
    images: Array.from(articleWithEnlargement.querySelectorAll("img"))
  }
}

export function initImageEnlargement() {
  const articleWithEnlargement = document.querySelector(
    ARTICLE_ENLARGEMENT_TOGGLE_SELECTOR
  )

  if (!articleWithEnlargement) {
    return
  }

  const state = getImageEnlargementState()

  // Escape close handler
  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      closeCurrentImage(state)
    }
  })

  // Outside click close handler
  document.addEventListener("click", (e) => {
    if (state.images.includes(e.target)) {
      return
    }
    closeCurrentImage(state)
  })

  // Attach click handlers to all images
  for (const articlePhotoElement of state.images) {
    articlePhotoElement.addEventListener("click", (event) => {
      const currentPhoto = event.target

      if (currentPhoto === state.enlargedImage) {
        closeCurrentImage(state)
        return
      }

      closeCurrentImage(state)
      openImage(currentPhoto, state)
    })
  }
}
