export var initToggleGallery = function () {
    var homePage = document.getElementById("homepage")

    if (homePage) {
        var showButton = document.querySelector(".showButton")
        var changeButtonText = showButton.querySelector(".button__text")

        showButton.addEventListener("click", function () {

            var teamMembers = document.getElementById("our-team-desktop")

            if (teamMembers.style.display === "block") {
                teamMembers.style.display = "none"
                changeButtonText.innerHTML = "Zobrazit tým"

            } else {
                teamMembers.style.display = "block"
                changeButtonText.innerHTML = "Skrýt tým"
            }
        })
    }
}