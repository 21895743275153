export var initScrollButton = function () {

// functions for scroll up button
  var scrollUpButton = document.getElementById("scrollButton")

// show the button after scrolling 20px from up
  window.onscroll = function () {
    scrollFunction()
    scrollButtonHide()
  }

  const scrollFunction = () => {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      scrollUpButton.style.display = "block"
    } else {
      scrollUpButton.style.display = "none"
    }
  }

// after click scroll to the top of the document
  scrollUpButton.addEventListener("click", function topFunction() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  })

// check if browser = Samsung and hide scrollUpButton
  let detectBrowser = navigator.userAgent

  const scrollButtonHide = () => {
    if (detectBrowser.indexOf("SamsungBrowser") > -1) {
      scrollUpButton.style.display = "none"
    } else if (detectBrowser.indexOf("SznProhlizec") > -1 && screen.width < 640) {
      scrollUpButton.style.marginBottom = "40px"
    }
  }
}