// https://github.com/orestbida/cookieconsent
import "vanilla-cookieconsent"

export default function initCookies() {
  const cc = initCookieConsent()
  cc.run({
    current_lang: "cs",
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false
    theme_css: '../css/app.css',
    languages: {
      'cs': {
        consent_modal: {
          title: 'Používáme cookies!',
          description: 'Tento web používá k poskytování služeb a analýze návštěvnosti soubory cookie. <button type="button" data-cc="c-settings" class="cc-link">Nastavení</button>',
          primary_btn: {
            text: 'Přijmout vše',
            role: 'accept_all'
          },
          secondary_btn: {
            text: 'Odmítnout vše',
            role: 'accept_necessary'
          }
        },
        settings_modal: {
          title: 'Nastavení cookies',
          save_settings_btn: 'Uložit nastavení',
          accept_all_btn: 'Přijmout vše',
          reject_all_btn: 'Odmítnout vše',
          close_btn_label: 'Zavřít',
          cookie_table_headers: [
            {col1: 'Název'},
            {col2: 'Doména'},
            {col3: 'Expirace'},
            {col4: 'Účel'}
          ],
          blocks: [
            {
              title: 'Nastavení Cookies',
              description: 'Soubory cookie používám k zajištění základních funkcí webu a ke zlepšení vašeho online zážitku. Pro každou kategorii si můžete vybrat, zda chcete Cookies vypnout nebo zapnout. Další podrobnosti týkající se souborů Cookies a dalších citlivých údajů naleznete v úplném znění <a href="https://webstudio.team/cookies" class="cc-link">Ochrana soukromí</a>.'
            }, {
              title: 'Naprosto nezbytné soubory cookies',
              description: 'Jsou nezbytné k tomu, aby web fungoval, takže není možné je vypnout. Většinou jsou nastavené jako odezva na akce, které jste provedli, jako je požadavek služeb týkajících se bezpečnostních nastavení, přihlašování, vyplňování formulářů atp. Prohlížeč můžete nastavit tak, aby blokoval soubory cookies nebo o nich posílal upozornění. Mějte na paměti, že některé stránky bez těchto souborů nebudou fungovat. Tyto soubory cookies neukládají žádné osobní identifikovatelné informace.',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true
              }
            }, {
              title: 'Soubory cookie pro analýzu a zvýšení výkonu',
              description: 'Pomáhají sledovat počet návštěvníků a také z jakého zdroje provoz pochází, což nám umožňuje zlepšovat výkon stránky. Můžeme s nimi určovat, které stránky jsou nejoblíbenější a které nejsou oblíbené, a také sledovat, jakým způsobem se návštěvníci na webu pohybují. Všechny informace, které soubory cookies shromažďují, jsou souhrnné a anonymní. Pokud soubory cookies nepovolíte, nebudeme vědět, kdy jste navštívili naši stránku.',
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false
              },
              cookie_table: [
                {
                  col1: '^_ga',
                  col2: 'google.com',
                  col3: '2 roky',
                  col4: 'GoogleAnalytics: Používá se k rozeznávání uživatelů.',
                  is_regex: true
                },
                {
                  col1: '_gid',
                  col2: 'google.com',
                  col3: '1 den',
                  col4: 'GoogleAnalytics: Používá se k rozeznávání uživatelů.',
                }
              ]
            }, {
              title: 'Soubory cookie pro reklamu a cílení',
              description: 'Tyto soubory cookie shromažďují informace o tom, jak web používáte, které stránky jste navštívili a na které odkazy jste klikli. Všechna data jsou anonymizována a nelze je použít k vaší identifikaci',
              toggle: {
                value: 'targeting',
                enabled: false,
                readonly: false
              }
            },
            {
              title: 'Více informací',
              description: 'Máte-li jakékoli dotazy ohledně našich zásad týkajících se souborů cookie a vašich voleb, prosím <a class="cc-link" href="https://webstudio.team/#kontakt">kontaktujte nás</a>.',
            }
          ]
        }
      }
    }
  })

  const cookieSettingsButton = document.getElementById('js-btn-cookies-settings')
  cookieSettingsButton.addEventListener('click', () => {
    cc.showSettings()
  })
}
